module.exports = [{
      plugin: require('/opt/build/repo/demo/node_modules/gatsby-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/opt/build/repo/demo/node_modules/gatsby-theme-portfolio/src/components/common/Layout/index.jsx"}},
    },{
      plugin: require('/opt/build/repo/demo/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#6b63ff","showSpinner":false},
    },{
      plugin: require('/opt/build/repo/demo/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-88875900-7","head":true},
    }]
