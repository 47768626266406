// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-theme-portfolio-src-pages-404-mdx": () => import("/opt/build/repo/demo/node_modules/gatsby-theme-portfolio/src/pages/404.mdx" /* webpackChunkName: "component---node-modules-gatsby-theme-portfolio-src-pages-404-mdx" */),
  "component---node-modules-gatsby-theme-portfolio-src-pages-index-mdx": () => import("/opt/build/repo/demo/node_modules/gatsby-theme-portfolio/src/pages/index.mdx" /* webpackChunkName: "component---node-modules-gatsby-theme-portfolio-src-pages-index-mdx" */),
  "component---src-pages-about-mdx": () => import("/opt/build/repo/demo/src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-services-mdx": () => import("/opt/build/repo/demo/src/pages/services.mdx" /* webpackChunkName: "component---src-pages-services-mdx" */)
}

